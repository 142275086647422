const InterFaceMixin = {
  data(){
    return {
      functionData : {
        registerForCard : () => { window.FirFinInterface.registerForCard() },
        missingCard : (data) => { window.FirFinInterface.missingCard(data) },
        webEnd : () => { window.FirFinInterface.webEnd() }
      }
    }
  },
  methods: {
    sendInterface(name,data,dataKeyName){
      const isData = Boolean(data && dataKeyName);
      //안드로이드
      if ( this.$varUA === 'ANDROID') {
        this.functionData[name](isData ? data : '')
      }
      //IOS
      else if ( this.$varUA === 'IOS' ) {
        let msg;
        if(isData){
          msg = {};
          msg.name = name;
          msg[dataKeyName] = data;
        }else{
          msg = name;
        }
        // if(!window.webkit) return;
        window.webkit.messageHandlers.FirFinInterface.postMessage(msg);
      }
      return;
    },
    customSendInterface(andInterfaceFunction, iosInterfaceFunction){
      //안드로이드
      if ( this.$varUA === 'ANDROID') {
        andInterfaceFunction();
      }
      //IOS
      else if ( this.$varUA === 'IOS' ) {
        iosInterfaceFunction();
      }
      return;
    },
    webEnd(){
      //IOS
      if ( this.$varUA === 'IOS' ) {
        window.webkit.messageHandlers.FirFinInterface.postMessage('webEnd');
      }
      //안드로이드
      else {
        window.FirFinInterface.webEnd();
      }
      return;
    },
    pageMove(pageName){
      console.log('pageMove',pageName)
      this.sendInterface('pageMove',pageName,'pageName');
    },
  }
}
export default InterFaceMixin;
